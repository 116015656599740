import type { DeviceState } from '@cxnpl/api/types';
import * as Device from 'expo-device';
import { Platform } from 'react-native';
import * as Application from 'expo-application';

export const createDeviceInfo = async (deviceId: string): Promise<DeviceState> => {
  if (Platform.OS === 'web') {
    return {
      deviceId,
      deviceType: 'WEB',
      webFields: {
        model: typeof navigator !== 'undefined' ? navigator.userAgent : '',
      },
    };
  }
  return {
    deviceId,
    deviceType: 'MOBILE',
    mobileFields: {
      model: `${Device.modelName}`,
      osName: Platform.OS,
      osVersion: Platform.Version.toString(),
      isTablet: Boolean(Device.deviceType === Device.DeviceType.TABLET),
      isEmulator: !Device.isDevice,
      bundleId: Application.applicationId ?? undefined,
      appName: Application.applicationName ?? undefined,
      appVersion: Application.nativeApplicationVersion ?? undefined,
      appInstallTime: (await Application.getInstallationTimeAsync()).getTime(),
    },
  };
};
