import { createSlice, type PayloadAction } from '@reduxjs/toolkit';

export interface ChatState {
  unreadCount: number;
}

const initialState: ChatState = {
  unreadCount: 0,
};

export const chatSlice = createSlice({
  name: 'chat',
  initialState,
  reducers: {
    setUnreadCount: (state, action: PayloadAction<{ unreadCount: number }>) => {
      state.unreadCount = action.payload.unreadCount;
    },
  },
});

export const { setUnreadCount } = chatSlice.actions;

export default chatSlice.reducer;
