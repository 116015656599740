import { createSlice, type PayloadAction } from '@reduxjs/toolkit';
import { appClient } from 'app/utils/appClient';
import type { TokenInfo } from './types';

export interface AuthState {
  inactivityLogout: boolean;

  // Auth0 config
  auth0Config: {
    domain: string;
    clientId: string;
  };

  // Tokens
  tokenInfo: TokenInfo;
  accessToken: string | null;
  refreshToken: string | null;
  idToken: string | null;

  // Temp PIN to confirm
  confirmPin: string | null;

  // Login error
  loginError?: string;
}

export const initialAuthState: AuthState = {
  inactivityLogout: false,

  // Auth0 config
  auth0Config: {
    domain: 'auth0Domain', // Cannot be an empty string, otherwise there will be a runtime error
    clientId: 'auth0ClientId', // Cannot be an empty string, otherwise there will be a runtime error
  },

  // Tokens
  tokenInfo: {
    userId: '',
    nickname: '',
    entityAccountId: '',
    permissionIds: [],
    roleIds: [],
    requirementIds: [],
    kycStatus: 'INCOMPLETE',
    permissionsStripped: false,
    intercomHmac: '',
    expiry: null, // Api token expiration time in epoch time milliseconds
  },
  accessToken: null,
  refreshToken: null,
  idToken: null,

  // Temp PIN to confirm
  confirmPin: null,

  // Login error
  loginError: undefined,
};

export const authSlice = createSlice({
  name: 'auth',
  initialState: initialAuthState,
  reducers: {
    setInactivityLogout: (state, action: PayloadAction<AuthState['inactivityLogout']>) => {
      state.inactivityLogout = action.payload;
    },
    setAuth0Config(state, action: PayloadAction<AuthState['auth0Config']>) {
      state.auth0Config = action.payload;
    },
    setTokenInfo(state, action: PayloadAction<AuthState['tokenInfo']>) {
      state.tokenInfo = action.payload;
    },
    resetTokenInfo(state) {
      state.tokenInfo = { ...initialAuthState.tokenInfo };
    },
    setTokens(
      state,
      action: PayloadAction<{
        accessToken: AuthState['accessToken'];
        refreshToken: AuthState['refreshToken'];
        idToken: AuthState['idToken'];
      }>
    ) {
      // Only set tokens on mobile, as web uses cookies
      if (appClient === 'MOBILE') {
        state.accessToken = action.payload.accessToken;
        state.refreshToken = action.payload.refreshToken;
        state.idToken = action.payload.idToken;
      } else {
        state.accessToken = null;
        state.refreshToken = null;
        state.idToken = null;
      }
    },
    resetAuthState(state) {
      // Only reset specific values
      state.accessToken = null;
      state.idToken = null;
      state.refreshToken = null;
      state.tokenInfo = { ...initialAuthState.tokenInfo };
      state.inactivityLogout = false;
    },
    setConfirmPin(state, action: PayloadAction<AuthState['confirmPin']>) {
      state.confirmPin = action.payload;
    },
    setLoginError(state, action: PayloadAction<AuthState['loginError']>) {
      state.loginError = action.payload;
    },
  },
});

export const {
  setInactivityLogout,
  setAuth0Config,
  setTokenInfo,
  resetTokenInfo,
  setTokens,
  resetAuthState,
  setConfirmPin,
  setLoginError,
} = authSlice.actions;

export default authSlice.reducer;
